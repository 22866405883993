.wrap {
  margin: auto;
}

.icon {
  animation: rotate 2s linear infinite;
  transform-origin: center;
  color: var(--theme-text-secondary);
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
