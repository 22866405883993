.buttons {
  display: flex;
  align-items: stretch;
  border: 1px solid var(--theme-border);
  border-radius: 20px;
  padding: 4px;
}

.button {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--theme-text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 600;
}

.button.auto {
  margin: 0 2px;
  width: auto;
  padding: 4px 8px;
}

.button.active {
  background-color: var(--color-brand);
  color: var(--color-white);
}

[data-theme='dark'] .button.active {
  background-color: #fff;
  color: var(--color-valhalla);
}
