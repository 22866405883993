.footer {
  padding: 20px 24px;
  background: var(--theme-background);
  border-top: 1px solid var(--theme-border);
  box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
}

.link {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: var(--theme-text-secondary);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  margin-right: 48px;
}

.link:hover {
  color: var(--theme-text);
}

.link svg {
  margin-right: 4px;
}
