/* https://css-tricks.com/custom-scrollbars-in-webkit/ */
::-webkit-scrollbar {
  /* 1 */
  height: 8px;
}

::-webkit-scrollbar:horizontal {
  /* 1 */
  height: 8px;
}

::-webkit-scrollbar:vertical {
  /* 1 */
  width: 8px;
}

::-webkit-scrollbar-button {
  /* 2 */
}

::-webkit-scrollbar-track {
  /* 3 */
  background: none;
}

::-webkit-scrollbar-track-piece {
  /* 4 */
  background: rgba(106, 107, 108, 0.2);
}

::-webkit-scrollbar-thumb {
  /* 5 */
  background: rgba(106, 107, 108, 0.4);
}

::-webkit-scrollbar-corner {
  /* 6 */
}

::-webkit-resizer {
  /* 7 */
}

/* http://chir.ag/projects/name-that-color/ */
/* https://www.color-blindness.com/color-name-hue/ */

:root {
  --color-white: #fff;
  --color-foreground: #f7f7f7;
  --color-gray: #e5e5e5;
  --color-darkgray: #b2b2b2;
  --color-lightgray: #d1d1d1;
  --color-brand: #2935ff;
  --color-green: #28a745;
  --color-yellow: #ffc940;
  --color-red: #f00;
  --color-black: #2d2926;
  --color-pitch-black: #0c0c0c;
  --color-gainsboro: #d8d8d8;
  --color-salomie: #fedd8b;
  --color-tulip-tree: #e6b539;
  --color-black-rock: #252936;
  --color-valhalla: #2c3040;
  --color-blue-zodiac: #393e54;
  --color-manatee: #858ca7;
  --color-portage: #8ba6de;
  --rgb-foreground: 247, 247, 247;
  --rgb-black: 45, 41, 38;
  --rgb-yellow: 255, 201, 64;
  --rgb-white: 255, 255, 255;
  --rgb-blue-zodiac: 57, 62, 84;
  --rgb-darkgray: 178, 178, 178;
  --rgb-lightgray: 209, 209, 209;
  --rgb-black-rock: 37, 41, 54;
  --rgb-brand: 41, 53, 255;
  --vertical-gap: 24px;
  --control-arrow-down: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" fill="%23b2b2b2"> <polygon points="0,0 14,0 7,8" /></svg>');

  --theme-background: var(--color-white);
  --theme-background-secondary: var(--color-foreground);
  --theme-text: var(--color-black);
  --theme-text-secondary: var(--color-darkgray);
  --theme-border: var(--color-gray);
  --theme-map: var(--color-gray);
  --theme-link: var(--color-brand);
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --theme-background: var(--color-valhalla);
    --theme-background-secondary: var(--color-black-rock);
    --theme-text: var(--color-white);
    --theme-text-secondary: var(--color-manatee);
    --theme-border: var(--color-blue-zodiac);
  }
}

[data-theme='light'] {
  --theme-background: var(--color-white);
  --theme-background-secondary: var(--color-foreground);
  --theme-text: var(--color-black);
  --theme-text-secondary: var(--color-darkgray);
  --theme-border: var(--color-gray);
} */

[data-theme='dark'] {
  --theme-background: var(--color-valhalla);
  --theme-background-secondary: var(--color-black-rock);
  --theme-text: var(--color-white);
  --theme-text-secondary: var(--color-manatee);
  --theme-border: var(--color-blue-zodiac);
  --theme-map: var(--color-blue-zodiac);
  --theme-link: var(--color-portage);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus:not(:focus-visible) {
  outline: 0;
}

body.using-mouse :focus {
  outline: none;
}

body.using-mouse :focus-within {
  outline: none;
}

body {
  margin: 0;
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--theme-text);
  background-color: var(--theme-background);
  font-size: 15px;
  line-height: 1.0666;
  overflow: hidden;
}

button {
  cursor: pointer;
}

#root {
  height: 100vh;
  display: grid;
  grid-template-rows: min-content auto min-content;
}

#root[inert] {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#main {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

#notifications-root {
  z-index: 20;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 8px;
}

#notifications-root:empty {
  display: none;
}

strong {
  font-weight: 600;
}

input[type='search']::-ms-clear,
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.button {
  font-family: inherit;
  cursor: pointer;
  text-decoration: none;
}

.button:disabled {
  cursor: not-allowed !important;
  background-color: var(--color-gray) !important;
  color: var(--color-darkgray) !important;
}

.button.button--primary {
  border-radius: 4px;
  border: 0;
  min-height: 40px;
  padding: 6px 24px 6px 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 0.2px;
  transition: 200ms ease all;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background-color: var(--color-brand);
  color: #fff;
  font-weight: 600;
}

.button.button--primary:hover {
  background-color: #003fe3;
}

.button.button--primary:active {
  border-style: inset;
}

.button.button--primary:focus {
  box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.3);
  border: 0;
}

.button.button--secondary {
  border-radius: 4px;
  border: 0;
  min-height: 40px;
  padding: 6px 24px 6px 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 0.2px;
  transition: 200ms ease all;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background-color: var(--color-black);
  color: var(--color-white);
  font-weight: 600;
}

[data-theme='dark'] .button.button--secondary {
  background-color: var(--color-white);
  color: var(--color-black);
}

.button.button--secondary:hover {
  background-color: var(--color-pitch-black);
}

[data-theme='dark'] .button.button--secondary:hover {
  background-color: var(--color-gray);
}

.button.button--secondary:active {
  border-style: inset;
}

.button.button--secondary:focus {
  box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.3);
  border: 0;
}

.section-title {
  font-size: 11px;
  line-height: 12px;
  font-weight: 600;
  margin: 0;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.thead {
  border-top: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
  background-color: rgba(var(--rgb-foreground), 0.3);
}

.thead th {
  text-align: left;
  font-size: 11px;
  line-height: 16px;
  color: rgba(var(--rgb-black), 0.6);
}

.thead th,
.tbody td {
  padding: 12px;
}

.thead th:first-of-type,
.tbody td:first-of-type {
  padding-left: 24px;
}

.thead th:last-of-type,
.tbody td:last-of-type {
  padding-right: 24px;
}

.tbody tr + tr {
  border-top: 1px solid var(--theme-border);
}

[data-theme='dark'] .thead {
  border-top: 1px solid var(--theme-border);
  border-bottom: 1px solid var(--theme-border);
  background-color: rgba(0, 0, 0, 0.03);
}

[data-theme='dark'] .thead th {
  color: var(--color-manatee);
}

[data-theme='dark'] .tbody tr + tr {
  border-top: 1px solid rgb(var(--rgb-blue-zodiac), 0.3);
}
