.notification {
  background-color: var(--color-black);
  color: var(--color-white);
  max-width: 558px;
  margin: 10px auto;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 15px;
  line-height: 16px;
}

.notification.error {
  background-color: var(--color-red);
  color: var(--color-white);
}

.notification.info {
  background-color: var(--color-brand);
  color: var(--color-white);
}

.notification.warning {
  background-color: var(--color-yellow);
  color: var(--color-white);
}

.notification.success {
  background-color: var(--color-green);
  color: var(--color-white);
}

.close {
  margin-left: auto;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

.close:hover {
  opacity: 0.8;
}

.close svg {
  width: 100%;
}
