.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px;
  background-color: var(--theme-background);
}

.logoWrap {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: var(--theme-text);
  text-decoration: none;
}

.logoWrap span {
  margin-left: 16px;
}

.logoWrap:hover span {
  opacity: 0.8;
}

.addLink {
  margin-left: auto;
}
